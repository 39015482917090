<template>
  <a-card title="地理位置">
    <a-row class="map-crad">
      <a-col :span="18">
        <!-- <img :src="position" class="map-position" alt="定位" /> -->
        <map-search class="map-search" @emitValue="getResult"></map-search>
        <my-map class="map-house" @emitLocation="getMapLocation" :location="location"></my-map>
      </a-col>
      <a-col :span="6" style="" class="map-form">
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }" class="left" @submit="handleSubmit">
          <!-- <a-form-item label="地址">
            <a-input
              disabled
              v-decorator="['address', { rules: [{ required: true, message: '请点击地图上选取地理位置!' }] }]"
            />
          </a-form-item> -->
          <a-form-item label="经度">
            <a-input
              disabled
              v-decorator="['lng', { rules: [{ required: true, message: '请点击地图上选取地理位置!' }] }]"
            />
          </a-form-item>
          <a-form-item label="纬度">
            <a-input
              disabled
              v-decorator="['lat', { rules: [{ required: true, message: '请点击地图上选取地理位置!' }] }]"
            />
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 12, offset: 10 }">
            <a-button type="primary" html-type="submit" :loading="saveLoading" style="margin-right: 15px;">
              保存
            </a-button>
            <a-button type="default" @click="resetForm">
              重置
            </a-button>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { MyMap, MapSearch } from '@/components'
import { houseGet, editHouses } from '@/api/house'
import position from '@/assets/img/position.svg'
export default {
  components: {
    MyMap,
    MapSearch
  },
  data() {
    return {
      map: null,
      location: null,
      form: this.$form.createForm(this, { name: 'coordinated' }),
      house: null,
      houseId: null,
      houseLink: null,
      orticeTitle: '楼盘地理位置',
      position,
      saveLoading: false
    }
  },
  mounted() {
    this.getHouseInfo(this.houseId)
  },
  created() {
    this.houseId = this.$route.query.id
    this.houseLink = this.$route.query.link
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.saveLoading = true
          console.log('Received values of form: ', values)
          const params = {
            longitude: values.lng,
            latitude: values.lat
          }
          editHouses(this.houseId, params)
            .then(res => {
              this.saveLoading = false
              this.$notification.success({
                message: this.noticeTitle,
                description: '更新成功'
              })
            })
            .catch(() => {
              this.saveLoading = false
            })
        }
      })
    },
    getResult(data) {
      if (data) {
        this.setForm(data)
      }
    },
    getMapLocation(data) {
      console.log(data, 'data')
      if (data) {
        this.setForm(data)
      }
    },
    setForm(data) {
      const newArr = data.split(',')
      const lng = newArr[0]
      const lat = newArr[1]
      const address = newArr[2]
      this.form.setFieldsValue({
        lng,
        lat,
        address
      })
      this.location = data
    },
    getHouseInfo(id) {
      houseGet(id).then(res => {
        this.house = res
        if (res.longitude && res.latitude) {
          this.location = `${res.longitude},${res.latitude}`
          this.form.setFieldsValue({
            lng: res.longitude,
            lat: res.latitude
          })
        } else {
          this.$notification.warning({
            message: this.noticeTitle,
            description: '请先设置楼盘地理位置'
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.map-crad {
  overflow: hidden;
  .map-form {
    padding: 20px;
  }
  .map-search {
    position: absolute;
    width: 20% !important;
    right: 20px;
    top: 20px;
    z-index: 8;
  }
  .map-house {
    z-index: 0;
  }
  .map-position {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-80%);
    z-index: 8;
  }
}
</style>
